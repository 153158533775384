import { Injectable } from '@angular/core';

import DxDataGrid from 'devextreme/ui/data_grid';
import _ from 'lodash';

import { ViewEvent } from 'src/app/shared/models';
import { environment } from '../../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class HelperService {
	public static orderEvents(viewEvents: ViewEvent[], column: string, direction: string) {
		return _.orderBy(viewEvents, [e => (e.followUpDate ?? new Date()).valueOf(), column], ['asc', direction === 'desc' ? 'desc' : 'asc']);
	}

	public sanitizeErrorMessage(message: string): string[] {
		return message.includes('Unexpected token < in JSON')
			? ['The request failed. We have been notified and are working to fix the issue. Please check back again in 30 minutes.']
			: message
					.replace('Unexpected error value: ', '')
					.replace('Network error: Http failure response for (unknown url): 0 Unknown Error', 'Service Timeout')
					.replace(/"/g, '')
					.replace('{ error: ', '')
					.replace('. }', '.')
					.split('|');
	}

	public getErrorMessage(res: {
		graphQLErrors?: { message: string }[];
		networkError?: { body?: { errors: Error[] }; message?: string };
		error?: { message: { response: string } };
		message?: string;
	}): string[] {
		if (res) {
			if (res.graphQLErrors?.length) {
				return this.sanitizeErrorMessage(res.graphQLErrors[0].message);
			} else if (res.networkError?.body?.errors) {
				return this.sanitizeErrorMessage(res.networkError.body.errors.map(e => e.message).join('|'));
			} else if (res.networkError?.message) {
				return this.sanitizeErrorMessage(res.networkError.message);
			} else if (res.error?.message) {
				if (res.error.message?.response) {
					return this.sanitizeErrorMessage(res.error.message.response);
				} else {
					return this.sanitizeErrorMessage(typeof res.error.message === 'string' ? res.error.message : JSON.stringify(res.error.message));
				}
			} else if (res.message) {
				return this.sanitizeErrorMessage(res.message);
			}
		}

		return ['Unknown Error'];
	}

	public left(str: string, count: number): string {
		return str.length > count ? str.slice(0, count - str.length) : str;
	}

	public removeLastCharacter(str: string | null | undefined, char: string): string {
		if (!str) {
			return '';
		}

		return str.endsWith(char) ? this.left(str, str.length - 1) : str;
	}

	public getAttachmentPath(path: string, withToken = false): string {
		const token = withToken ? '?access_token=' + environment.apiKey : '';
		return !path.includes('http') ? environment.apiUrl + path + token : path;
	}

	//Build filter map functions
	public isFunction(variable: any): boolean {
		return variable instanceof Function;
	}

	public isString(variable: any): boolean {
		return typeof variable === 'string';
	}

	public isNullOrUndefined(value: any): boolean {
		return value === undefined || value === null;
	}

	public toBoolean(variable: any): boolean {
		if ([undefined, null, 'null', 'undefined'].includes(variable)) {
			return false;
		}
		if (typeof variable === 'string') {
			if (['yes', 'y', 'true', '1'].includes(variable.toLowerCase())) {
				return true;
			} else if (['no', 'n', 'false', '0'].includes(variable.toLowerCase())) {
				return false;
			}
		} else if (typeof variable === 'number') {
			return variable === 1 ? true : false;
		}

		return variable;
	}

	public getColumnFieldName(dataGrid: DxDataGrid, getter: any): string {
		if (this.isFunction(getter)) {
			const column = dataGrid.columnOption(getter.columnIndex);
			return column.dataField;
		} else {
			return getter;
		}
	}

	public getColumnFieldType(dataGrid: DxDataGrid, getter: any): string {
		if (this.isFunction(getter)) {
			const column = dataGrid.columnOption(getter.columnIndex);
			return column.dataType;
		} else if (this.isString(getter)) {
			const column = dataGrid.columnOption(getter.replace(/^!/, ''));
			if (column) {
				return column.dataType;
			}

			return null;
		}

		return null;
	}

	public buildFilterMap(dataGrid: DxDataGrid, filterValues: any, filterMapRoot?: any): any {
		let filterMap = filterMapRoot;
		if (filterValues) {
			// extract bang operator
			if (filterValues.length && filterValues[0] === '!') {
				if (Array.isArray(filterValues[1]) && Array.isArray(filterValues[1][0])) {
					for (const filterValue of filterValues[1]) {
						if (Array.isArray(filterValue)) {
							filterValue[0] = '!' + filterValue[0];
						}
					}
				} else {
					filterValues[1][0] = '!' + filterValues[1][0];
				}
				filterValues.shift();
			}
			// organize into filter map
			for (let i = 0; i < filterValues.length; i++) {
				if (i === 0 && (this.isString(filterValues[i]) || this.isFunction(filterValues[i]))) {
					const columnFieldName = this.getColumnFieldName(dataGrid, filterValues[0]);
					const columnFieldNameOpposite = '!' + columnFieldName;
					const columnFieldType = this.getColumnFieldType(dataGrid, columnFieldName);
					if (columnFieldName.includes('Date') && columnFieldType === 'date') {
						if (!filterMap[columnFieldName]) {
							filterMap[columnFieldName] = [];
						}
						if (filterValues[1] === '>=') {
							filterMap[columnFieldName].push([filterValues]);
						} else {
							filterMap[columnFieldName][filterMap[columnFieldName].length - 1].push(filterValues);
						}
					} else if (!this.isNullOrUndefined(filterMap[columnFieldNameOpposite])) {
						if (Array.isArray(filterMap[columnFieldNameOpposite])) {
							filterMap[columnFieldNameOpposite].push(!isNaN(filterValues[2]) ? filterValues[2] : this.toBoolean(`${filterValues[2]}`));
						} else {
							filterMap[columnFieldNameOpposite] = [filterMap[columnFieldNameOpposite], filterValues[2]];
						}
					} else if (!this.isNullOrUndefined(filterMap[columnFieldName])) {
						if (Array.isArray(filterMap[columnFieldName])) {
							filterMap[columnFieldName].push(!isNaN(filterValues[2]) ? filterValues[2] : this.toBoolean(`${filterValues[2]}`));
						} else {
							filterMap[columnFieldName] = [filterMap[columnFieldName], filterValues[2]];
						}
					} else {
						if (Array.isArray(filterValues[2])) {
							filterMap[columnFieldName] = !isNaN(filterValues[2][2]) ? filterValues[2][2] : this.toBoolean(`${filterValues[2][2]}`);
						} else {
							filterMap[columnFieldName] = !isNaN(filterValues[2]) ? filterValues[2] : this.toBoolean(`${filterValues[2]}`);
						}
					}
				} else if (Array.isArray(filterValues[i])) {
					filterMap = this.buildFilterMap(dataGrid, filterValues[i], filterMap);
				}
			}

			return filterMap;
		} else {
			return filterMap;
		}
	}
}
