import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
	faCardsBlank as farCardsBlank,
	faCircleCheck as farCircleCheck,
	faNotes as farNotes,
	faRefrigerator as farRefrigerator,
} from '@fortawesome/pro-regular-svg-icons';
import {
	faArrowRightFromBracket as fasArrowRightFromBracket,
	faArrowsSpin as fasArrowsSpin,
	faBars as fasBars,
	faBookOpen as fasBookOpen,
	faCalendarDays as fasCalendarDays,
	faCheck as fasCheck,
	faChevronLeft as fasChevronLeft,
	faChevronRight as fasCheveronRight,
	faCircleCaretRight as fasCircleCaretRight,
	faCircleCheck as fasCircleCheck,
	faCircleQuestion as fasCircleQuestion,
	faCircleXmark as fasCircleXmark,
	faDatabase as fasDatabase,
	faEdit as fasEdit,
	faEllipsisH as fasEllipsisH,
	faEnvelope as fasEnvelope,
	faExchangeAlt as fasExchangeAlt,
	faEye as fasEye,
	faEyeSlash as fasEyeSlash,
	faGear as fasGear,
	faGears as fasGears,
	faGift as fasGift,
	faGrid2 as fasGrid2,
	faList as fasList,
	faLock as fasLock,
	faPencil as fasPencil,
	faPhone as fasPhone,
	faPlus as fasPlus,
	faRightLeftLarge as fasRightLeftLarge,
	faRotate as fasRotate,
	faSignOutAlt as fasSignOutAlt,
	faSpinner as fasSpinner,
	faTimes as fasTimes,
	faTrash as fasTrash,
	faTriangleExclamation as fasTriangleExclamation,
	faUser as fasUser,
	faUsers as fasUsers,
	faUserSecret as fasUserSecret,
	faXmark as fasXmark,
} from '@fortawesome/pro-solid-svg-icons';

@NgModule({
	declarations: [],
	imports: [CommonModule, FontAwesomeModule],
	exports: [FontAwesomeModule],
})
export class IconsModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(
			fasUser,
			fasLock,
			fasEye,
			fasEyeSlash,
			fasUser,
			fasSignOutAlt,
			fasEnvelope,
			fasExchangeAlt,
			fasSpinner,
			fasEdit,
			fasCheck,
			fasTimes,
			fasTrash,
			fasUserSecret,
			fasBars,
			fasGears,
			fasArrowRightFromBracket,
			fasGear,
			fasCircleQuestion,
			fasGrid2,
			fasDatabase,
			fasRightLeftLarge,
			farCardsBlank,
			fasUsers,
			fasBookOpen,
			fasPhone,
			fasCalendarDays,
			fasGift,
			farNotes,
			fasChevronLeft,
			fasArrowsSpin,
			farCircleCheck,
			fasEllipsisH,
			fasCheveronRight,
			fasPlus,
			fasPencil,
			fasCircleXmark,
			fasRotate,
			fasTriangleExclamation,
			farRefrigerator,
			fasXmark,
			fasList,
			fasCircleCheck,
			fasCircleCaretRight,
		);
	}
}
